@import '../../node_modules/bootstrap/scss/_functions';
@import 'theme/functions';

@import '../../node_modules/bootstrap/scss/mixins';
@import 'theme/mixins';

@import 'user-variables';
@import 'theme/variables';
@import '../../node_modules/bootstrap/scss/variables';

//
//
// user.scss
//
// Place your own theme CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.
.navbar-vertical.navbar-expand-lg .navbar-vertical-content .navbar-nav .nav-link.dropdown-indicator {
    padding-left: 0rem;
}

.navbar-vertical.navbar-expand-lg .navbar-vertical-content .navbar-nav .nav .nav-link {
    padding-left: 2.3125rem;
    scroll-margin-top: 200px;
}
.navbar-vertical.navbar-expand-lg .navbar-vertical-content .navbar-nav .nav-link {
    padding-top: 0.35rem;
    padding-bottom: 0.35rem;
    padding-left: 0.75rem;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    border-radius: 0.5rem;
}
.content {
    min-height: 100vh;
    padding: calc(var(--phoenix-navbar-top-height) + 2rem) 1.5rem 6.375rem 1.5rem;
    padding-bottom: 6rem;
}